@blue: #6598b8;
@lightBlue: #bbdaed;
@darkBlue: #4a6187;

@select-border-color: @blue;
@input-height-base: 48px;
@border-radius-base: 4px;
@table-header-bg: #f8f8f8;
@table-expanded-row-bg: #f7f9fe;
// @font-family: 
@font-family: 'proxima-nova';
@box-shadow: 0 9px 20px 0 rgba(74, 97, 135, 0.2);

// buttons
@btn-height-base: 52px;
@btn-border-radius-base: 4px;
@btn-primary-color: #fff;
@btn-primary-bg: @darkBlue;
@btn-primary-border: none;

@disabled-color: black;

.blue {
    color: @darkBlue;
}

.row-menu-wrapper {
    display: none;
}

.blue:hover {
    .row-menu-wrapper {
        display: block;
    }
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid @lightBlue;
}

.ant-table-tbody .table-expanded-row > td {
    box-shadow: @box-shadow;
}

.ant-table-column-sorters {
    padding-left: 5px;
    padding-right: 5px;
}

.hide {
    display: none;
}
